import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from '../src/app';

const root = createRoot(document.getElementById('root'));

root.render(

<React.StrictMode>
    <Auth0Provider
        domain="wavecard.uk.auth0.com"
        clientId="Gj2GsciMpKaOmh08xMggSTefDIony348"
        authorizationParams={{
        redirect_uri: window.location.origin
         }}
       >
        <App />
    </Auth0Provider>
  
</React.StrictMode>
);