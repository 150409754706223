import React from "react";

import LoginPage from "./components/LoginPage";

function App() {
  return (
    <>
     
      <LoginPage></LoginPage>
    </>
  );
}
export default App;
