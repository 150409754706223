import React from "react";
import "../components/LoginPage.css";
import AuthLogin from "../components/authlogin";
import UserProfile from "../components/userprofile";
import LogoutButton from "../components/authlogout";

function LoginPage() {
  return (
    <>
      <div className="hero-container">
        <p>
          Namaskar Hello
        </p>
        </div>

      <div className="v">
              <AuthLogin />
  
       
      </div>
    </>
  )
}

export default LoginPage;
